import React, { useState } from "react";
import certificates from "../utils/certificates";
const Certifications = () => {
  const [Category, setCategory] = useState(1);

  // const RenderedSwitch = () => {
  //   switch (Category) {
  //     case 1:
  //       return (
  //         <>
  //           <div className="cert-wrapper">
  //             <img src={Hackerrank1} alt="" />
  //             <h3>Hackerrank</h3>
  //           </div>
  //         </>
  //       );
  //     case 2:
  //       return <h1>FreeCodeCamp</h1>;
  //     case 3:
  //       return <h1>others</h1>;

  //     default:
  //       return <h1>Hackerrank</h1>;
  //   }
  // };

  return (
    <>
      <div className="cont certs" id="certs">
        <h1>Certifications</h1>
        <div className="cert-tab">
          <div className="left">
            <div className="tab" onClick={(e) => setCategory(1)}>
              Hackerrank
            </div>
            <div className="tab" onClick={(e) => setCategory(2)}>
              Coursera
            </div>
            <div className="tab" onClick={(e) => setCategory(3)}>
              FreeCodeCamp
            </div>
            <div className="tab" onClick={(e) => setCategory(4)}>
              Others
            </div>
          </div>
          <div className="right">
            {certificates[Category].map((item, index) => (
              <Certificate {...item} key={index} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Certificate = ({ imj, link, title }) => {
  const [Loaded, setLoaded] = useState(false);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="cert-wrapper">
        <img
          src={"/public/images/certificates/" + imj}
          alt={title + "certification"}
          fetchpriority="low"
          onLoad={(e) => setLoaded(true)}
        />
        {!Loaded ? (
          <div className="img-skelton">
            <div className="lin-grd"></div>{" "}
          </div>
        ) : (
          ""
        )}
        <h3>{title}</h3>
      </div>
    </a>
  );
};

export default Certifications;
