import { useState } from "react";
import { writeMe } from "../Services/ContactAPI";

function Contact() {
  const [Name, setName] = useState('');
  const [Subject, setSubject] = useState('');
  const [Message, setMessage] = useState('');

  const [Sending, setSending] = useState(false);
  const [Flag1, setFlag1] = useState(false);
  const [Flag2, setFlag2] = useState(false);
  const [Flag3, setFlag3] = useState(false);

  const changeState = (e , stateFunction)=>{
    stateFunction(e.target.value);
  }


  const errorMEssages = [
    {
      message : "Name must contain atleast 3 letters",
      active : Flag1,
    },
    {
      message : "Subject shall consist of minimum 2 letters",
      active : Flag2,
    },
    {
      message : "Message must contain atleast 5 letters",
      active : Flag3,
    },
    
  ]

  const setInitials = ()=>{
    setName('');
    setSubject('');
    setMessage('');
    window.alert("Sucessfully Send the message")
  }

  const validator = ()=>{
    setSending(true);
    var temp1 = false;
    var temp2 = false;
    var temp3 = false;
    Name.trim().length < 3 ? temp1 = false : temp1 = true  ;
    Subject.trim().length < 2 ? temp2 = false : temp2 = true;
    Message.trim().length < 5 ? temp3 = false : temp3 = true;

    setFlag1(!temp1);
    setFlag2(!temp2);
    setFlag3(!temp3);
    
    return temp1 && temp2 && temp3 ;
  }

  const writeLetter = async (e)=>{
    e.preventDefault();
    if (validator() === true){
      const done = await writeMe({Name : Name , Message : Message , Subject : Subject});
      setSending(false);
      done=== 200 ? setInitials() : window.alert("Error Occured");
      
    }
    else{
      setSending(false);
      return ;
    }
  } 

  return (
      
      <div id="contact">
        <form id="conform" onSubmit={writeLetter}>
          <h1 className="ph" style={{textAlign : "center" , marginBottom : "35px"}}>Contact me</h1>
          <input value={Name} onChange={e=> changeState(e , setName) } name='name' type="text" placeholder="Name"/> <br />
          <input value={Subject} onChange={e=> changeState(e , setSubject) } name='subect' type="text" placeholder="Subject"/> <br />
          <textarea value={Message} onChange={e=> changeState(e , setMessage) } placeholder="Type your message here..." name="message" id="" cols="10" rows="10"></textarea> <br />
          {errorMEssages.map(item => item.active ? <Error msg={item.message} /> : null)}  
          <button disabled={Sending} type="submit">Make Contact</button>
        </form>
      </div>
    
  )
}

const Error = (props)=>{
  return(
    <h5 style={{color : '#009688' , marginTop : "2px"}}> *{props.msg}</h5>
  )
}

export default Contact