const headers = {
    'Content-Type' : 'application/json',
}

const writeMe = async (body) =>{
    try {
        var response = await fetch('https://kazmisyed.com/api/contact/' ,
        {
            method : 'POST',
            headers : headers,
            credentials : 'include',
            body : JSON.stringify(body),
        });
        
        if(response.status === 200){
            return 200;
        }

    } catch (error) {
        return 300;
    }
}

export {writeMe}
