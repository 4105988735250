import Footer from "../Components/Footer";
import Services from "../Components/Services";
import Certifications from "../Components/Certifications";
import ProjectCatalogues from "../Components/ProjectCatalogues";
import Contact from "../Components/Contact";
import Skills from "../Components/Skills";
import Hero from "../Components/Hero";
import '../Asset/stylesheet/main.css';

const parallax = (e)=>{
    const r =[document.getElementById("hi") , document.getElementById("ha") , document.getElementById("hr")]
    r.forEach(
      (imj)=>{
        const movex = e.clientX * -1 * imj.getAttribute('data-value') /250
        const movey = e.clientY * imj.getAttribute('data-value') /250
        imj.style.transform = "translateX("+movex+"px) translateY("+movey+ "px)"
      }
    )
}

function LandingPage() {
  return (
    <>

      <div id="er" className='cont-wrap' style={{overflow:"hidden" , background : "#faff11",position:"relative", maxHeight: "101vh"}} onMouseMove={parallax} >
        {/* <NavBar /> */}
        <Hero />

      </div>

      <div className="cont-wrap">
        <Services className="cont" />
      </div>
      <div className="cont-wrap">
        <Certifications className="cont" />
      </div>
      <div className="cont-wrap">
        <ProjectCatalogues className="cont" />
      </div>
      <div className="cont-wrap">
        <Skills />
      </div>
        <Contact />
        <div className="cont-wrap footer-con">

        <Footer className="cont" />
        </div>
      </>

  )
}


//ROCK  
//bg radial-gradient(#dfd09b, #ffff00)
//border : #00ffaf
export default LandingPage