import { useState, useEffect } from "react";
import NavBar from "./NavBar";
import Hro from "../Asset/Images/BG-hero.png";
import Ava from "../Asset/Images/BG-avatar.png";
import List from "../utils/roles";
function Hero() {
  const [Ind, setInd] = useState(() => 0);
  const [Dev, setDev] = useState(() => List[Ind]);
  const [Reducer, setReducer] = useState(() => 0);

  const func = () => {
    var i = Dev;
    const y = setInterval(() => {
      if (i.length === 0) {
        setInd((Ind + 1) % List.length);
        setReducer(1);
        clearInterval(y);
      } else {
        i = i.slice(0, -1);
        setDev(i);
        // setDev(Dev.slice(0,-1))
      }
    }, 100);
  };

  const brhao = () => {
    var i = Dev;
    const y = setInterval(() => {
      if (i.length === List[Ind].length) {
        setReducer(0);
        clearInterval(y);
      } else {
        i = List[Ind].slice(0, i.length + 1);
        setDev(i);
      }
    }, 250);
  };

  useEffect(() => {
    setReducer(!Reducer);
  }, [Ind]);

  useEffect(() => {
    if (Reducer === 0) {
      setTimeout(func, 1500);
    } else if (Reducer === 1) {
      brhao();
    }
  }, [Reducer]);

  return (
    <div id="hero" className="cont">
      <NavBar />
      <div className="inh">
        <h1 style={{ fontWeight: "400", fontFamily: "Lucida HandWriting" }}>
          Welcome by{" "}
        </h1>
        <div id="hh">
          <h2>{Dev}</h2>
          <div id="line"></div>
        </div>
        <p>
          Full-stack web and mobile application developer to help you turn your
          ideas into reality with modern technical solutions for optimization{" "}
        </p>
      </div>
      <img id="hi" data-value="8" src={Hro} alt="" />
      <img id="ha" data-value="-5" src={Ava} alt="" />
      <a href="#contact">
        <button id="hr" data-value="6">
          Recruit Me
        </button>
      </a>
    </div>
  );
}

export default Hero;
