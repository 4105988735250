import React from "react";
import skill1 from "../Asset/Images/skills-python.png";
import skill2 from "../Asset/Images/skills-html.webp";
import skill3 from "../Asset/Images/skills-css.webp";
import skill4 from "../Asset/Images/skills-sass.png";
import skill5 from "../Asset/Images/logo-og.png";
import skill6 from "../Asset/Images/skills-node.png";
import skill7 from "../Asset/Images/skills-nextjs.png";
import skill8 from "../Asset/Images/skills-js.png";
import skill9 from "../Asset/Images/skills-c.png";
import skill10 from "../Asset/Images/skills-proteus.png";
import skill11 from "../Asset/Images/skills-express.png";
import skill12 from "../Asset/Images/skills-pspice.webp";
import skill13 from "../Asset/Images/skills-django.png";
import skill14 from "../Asset/Images/skills-assembly.png";
import skill15 from "../Asset/Images/skills-mysql.png";
import skill16 from "../Asset/Images/skills-mongo.png";
import skill17 from "../Asset/Images/skills-verilog.webp";
import skill18 from "../Asset/Images/skills-github.png";

function Skills() {
  const skill = [
    {
      name: "ReactJS/React Native",
      pic: skill5,
    },
    {
      name: "Python",
      pic: skill1,
    },
    {
      name: "HTML(5)",
      pic: skill2,
    },
    {
      name: "CSS(3)",
      pic: skill3,
    },
    {
      name: "Javascript",
      pic: skill8,
    },
    {
      name: "C/C++",
      pic: skill9,
    },
    {
      name: "Mips",
      pic: skill14,
    },
    {
      name: "NeXtJS",
      pic: skill7,
    },
    {
      name: "NodeJS",
      pic: skill6,
    },
    {
      name: "MySQL",
      pic: skill15,
    },
    {
      name: "Sass",
      pic: skill4,
    },
    {
      name: "MongoDB",
      pic: skill16,
    },
    {
      name: "Django",
      pic: skill13,
    },
    {
      name: "Pspice",
      pic: skill12,
    },
    {
      name: "Proteus",
      pic: skill10,
    },
    {
      name: "ExpressJS",
      pic: skill11,
    },
    {
      name: "Verilog",
      pic: skill17,
    },
    {
      name: "Github",
      pic: skill18,
    },
  ];

  const renderList = skill.map((skil, index) => {
    return (
      <div className="skill" key={index}>
        <img
          src={skil.pic}
          alt=""
          style={{ height: "80px", objectFit: "contain" }}
        />
        <h2>{skil.name}</h2>
      </div>
    );
  });

  return (
    <>
      <div className="cont">
        <div id="skills">
          <h1>Tools & Technologies</h1>
          <div id="boxs">{renderList}</div>
        </div>
      </div>
    </>
  );
}

export default Skills;
