export default {
  1: [
    {
      imj: "6.webp",
      title: "Hackerrank Frontend Developer Certification",
      link: "https://www.hackerrank.com/certificates/d6ba61776aef",
    },
    {
      imj: "5.webp",
      title: "Hackerrank React Basic Certification",
      link: "https://www.hackerrank.com/certificates/aabe405ba141",
    },
    {
      imj: "1.webp",
      title: "Hackerrank SQL Basic Certification",
      link: "https://www.hackerrank.com/certificates/7578e80c71a9",
    },
    {
      imj: "3.webp",
      title: "Hackerrank Javascript Intermediate Certification",
      link: "https://www.hackerrank.com/certificates/141f36f44b20",
    },
    {
      imj: "2.webp",
      title: "Hackerrank Javascript Basic Certification",
      link: "https://www.hackerrank.com/certificates/b40725d6a73c",
    },
    {
      imj: "4.webp",
      title: "Hackerrank Python Basic Certification",
      link: "https://www.hackerrank.com/certificates/97aee2f61842",
    },
  ],
  2: [
    {
      imj: "15.webp",
      title: "Coursera SQL Certification",
      link: "https://www.coursera.org/account/accomplishments/certificate/85E64QS3D8C7",
    },
    {
      imj: "16.webp",
      title: "Coursera CICD using Docker,Kubernetes OpenShift",
      link: "https://coursera.org/share/7bfa65d9da102a276e13a1e92cce216d",
    },
    {
      imj: "17.webp",
      title: "Coursera Backend Development using Django and SQL",
      link: "https://coursera.org/share/ac9ab0fcbea1491d7efe1b17ef23bb97",
    },
    {
      imj: "11.webp",
      title: "Coursera Data Structures Certification",
      link: "https://www.coursera.org/account/accomplishments/certificate/RQFXF6X8FYGU",
    },
    {
      imj: "13.webp",
      title: "Coursera Data Science Intro Certification",
      link: "https://www.coursera.org/account/accomplishments/certificate/GVJTU2TDDKE8",
    },
    {
      imj: "12.webp",
      title: "Coursera Data-Science Methodology Certification",
      link: "https://www.coursera.org/account/accomplishments/certificate/F8D6U7BU5UL8",
    },
    {
      imj: "14.webp",
      title: "Coursera Python Basic Certification",
      link: "https://www.coursera.org/account/accomplishments/records/PJ4NM23MY47P",
    },
  ],
  3: [
    {
      imj: "24.webp",
      title: "Backend Development and APIs Certification",
      link: "https://www.freecodecamp.org/certification/fcc909e2c92-bc6b-4ab2-9d97-4b557d5e85bf/back-end-development-and-apis",
    },
    {
      imj: "21.webp",
      title: "Responsive Web Design Certification",
      link: "https://www.freecodecamp.org/certification/fcc909e2c92-bc6b-4ab2-9d97-4b557d5e85bf/responsive-web-design",
    },
    {
      imj: "23.webp",
      title: "Frontend Development Libraries Certification",
      link: "https://www.freecodecamp.org/certification/fcc909e2c92-bc6b-4ab2-9d97-4b557d5e85bf/front-end-development-libraries",
    },
    {
      imj: "22.webp",
      title: "JS Algorithms and Data Structures Certification",
      link: "https://www.freecodecamp.org/certification/fcc909e2c92-bc6b-4ab2-9d97-4b557d5e85bf/javascript-algorithms-and-data-structures",
    },
  ],
  4: [
    {
      imj: "Badge1.webp",
      title: "IBM Data Science Orientation Badge",
      link: "https://www.credly.com/badges/64248019-71c5-436f-a20a-6150f943cf64",
    },
    {
      imj: "Badge2.webp",
      title: "IBM Developing Applications with SQL, Databases, and Django",
      link: "https://www.credly.com/badges/d9cd48f8-a9ca-41e7-89fc-7a3eae4b7db1/public_url",
    },
  ],
};
