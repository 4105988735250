import {ReactComponent as Logo} from '../Asset/Images/Logo.svg';
import {Link} from 'react-router-dom';

function NavBar() {
  
  // x.children.style.display = "initial"
  const showLinks = ()=>{
  let x = document.getElementById('links');
  x.style.maxWidth = "initial"
  x.style.transition = "235ms ease-in"
  x.children[0].style.display= "none"
  x.children[1].style.display= "flex"
  x.children[1].style.transform= "translateX(500px)"
  x.style.transition = "1000ms ease-in"
  x.style.transform = "translateX(-500px)";
}

const hideLinks = ()=> {
  let x = document.getElementById('links'); 
  x.style.transition = "800ms ease-in-out";
  x.style.transform= "translateX(0px)";
  setTimeout(() => {
      x.style.transition = "initial"
      x.children[1].style.display= "none";    
      x.children[0].style.display= "initial"
  }, 625);
}

return (
  <>
  
  <nav id='bn'>

      <Logo />
      <div id="links">
              <div id="hamburger" onClick={showLinks}>
                  <div className="lines"></div>
                  <div className="lines"></div>
                  <div className="lines"></div>
              </div>
              
              <div className="linkas">
              <svg onClick={hideLinks} id="hl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>
                  <ul>
                      <a href="#services">
                        <li>Services</li>
                        </a>
                      <a href="#certs">
                        <li>Certifications</li>
                        </a>
                      <a href="#projects">
                        <li>Projects</li>
                        </a>
                      <a href="#skills">
                        <li>Skills</li>
                        </a>
{/* 
                      <li><a className=".link" to="#">Services</a></li>
                      <li><a className=".link" to="#">Projects</a></li>
                      <li><a className=".link" to="#">About</a></li>
                      <li><a className=".link" to="#contact">Contact</a></li> */}
                  </ul>
              </div>
          </div>
  </nav>

  </>
)

}

export default NavBar