import React , {useLayoutEffect , useRef} from 'react';
import {ReactComponent as WebDevIcon} from '../Asset/Icons/webdevelopment-icon.svg'
import {ReactComponent as MobDevIcon} from '../Asset/Icons/mobiledevelopment-icon.svg'
import {ReactComponent as BugIcon} from '../Asset/Icons/bugdetection-icon.svg'

function Services() {

  
  const firstBox = useRef(null);



  const onScroll = () => {



    const topPos = firstBox.current.getBoundingClientRect().top;
    const scrollPos = window.scrollY + window.innerHeight;
    const bottomPos = firstBox.current.getBoundingClientRect().bottom;

    //Onscroll transition
    if ((scrollPos - topPos)  > window.innerHeight * 0.8 && scrollPos - bottomPos < window.innerHeight* 1.68  ) {
      // firstBox.current.children[0].style.transitionDuration = '0ms';
      // firstBox.current.children[0].style.transform = 'translateX(-90%)';
      // firstBox.current.children[0].style.opacity = '0';
      // firstBox.current.children[0].style.transitionDuration = '500ms';
      // firstBox.current.children[0].style.transitionFunction = 'ease-in';
      firstBox.current.children[0].style.animation = 'translation-animation 350ms ease-in';
      firstBox.current.children[1].style.animation = 'translation-animation3 350ms ease-in';
      firstBox.current.children[2].style.animation = 'translation-animation5 350ms ease-in';
      
      // firstBox.current.children[0].style.opacity = '1';
    }
    
    //overscroll transition
    else if(scrollPos - bottomPos > window.innerHeight* 1.68 || window.innerHeight - topPos < 0){
      
      firstBox.current.children[0].style.animation = 'translation-animation2 400ms ease-in ';
      firstBox.current.children[0].style.animationFillMode = 'forwards';
      firstBox.current.children[1].style.animation = 'translation-animation4 400ms ease-in ';
      firstBox.current.children[1].style.animationFillMode = 'forwards';
      firstBox.current.children[2].style.animation = 'translation-animation6 400ms ease-in ';
      firstBox.current.children[2].style.animationFillMode = 'forwards';
    }
  }


  useLayoutEffect(() => {
    window.addEventListener("scroll" , onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  
  })

  return (
  <>
    <div ref={firstBox} className='cont' id="services">
      <div id="wd" className="sb">
          <WebDevIcon />

        <div className="tb">
          <h1>
            Web development
          </h1>
          <p>
            You can have your own business and personal website with both frontend and backend. Just select a design and leave the rest to me. If you don't have a design let me select one for you. Feel free to <a style={{color:"#966625" , fontWeight:"500"}} href="#contact">reach-out</a>  
          </p>
        
        </div>
        </div>
      <div id="bf" className="sb">
        <BugIcon />
        <div className="tb">
          <h1>
            Bug Fixing
          </h1>
          <p>
            It is really an infuriating experience, when you don't know which part of your code is causing trouble. I would like to sapre you this trouble and will help you in solving those bugs. Feel free to <a style={{color:"#966625" , fontWeight:"500"}} href="#contact">reach-out</a>
          </p>
        
        </div>
      </div>
      <div id="pc" className="sb">
        <MobDevIcon />
      
        <div className="tb">
          <h1>
            Mobile Development
          </h1>
          <p>
            You are launching a mobile-app for your business, provide the design prototypes and I will develop the app for you using flutter and react native. Feel free to <a style={{color:"#966625" , fontWeight:"500"}} href="#contact">reach-out</a>
          </p>
        
        </div>
      </div>
    </div>
  
  </>
  )
}

export default Services